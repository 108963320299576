import React, { Component } from 'react';
import Footer from '../navs/Footer';
import MainBody from '../MainBody';
import Hero from '../Hero';
import NavBar from '../navs/Navbar';


class IndexPage extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Hero/>
                <MainBody />
                <Footer />
            </div>
        )
    }

}

export default IndexPage;
