import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { usePaystackPayment } from 'react-paystack'
import lSelector from 'jquery'

const MySwal = withReactContent(Swal)

type PayStackProps = {
    email:string
    phone:string
    amount:number
    name:string
    reference:string
}

type initializeClientForm = {
    token:string
    companyName:string
    phone:string
    email:string
    amount:number
    reference:string
    clientID:string
}

let finalFormSubmitData:initializeClientForm = {
    token: 'f7faad538d64155945c6f0db14ea75df',
    companyName: '',
    phone: '',
    email: '',
    amount: 0,
    reference: '',
    clientID: ''
}

const PaystackPaymentBtn = (props:PayStackProps) => {
    const initializePayment = usePaystackPayment({
        reference: props.reference,
        email: props.email,
        amount: props.amount * 100,
        publicKey: 'pk_live_843bd5850cf93c74ab09c3111c79e1f30b47d719',
        currency: 'GHS',
        subaccount: 'ACCT_0x1i2k9yckbnimp',
        label: props.name
    });

    finalFormSubmitData.companyName = props.name
    finalFormSubmitData.phone = props.phone
    finalFormSubmitData.email = props.email
    finalFormSubmitData.amount = props.amount
    finalFormSubmitData.reference = props.reference

    return (
        <div>
            <button type="button" className="paystack_form_btn d-none" onClick={() => {
                initializePayment(onPaystackSuccess, onPayStackClose)
            }} style={{ background: "#8BAC3D", color: '#fff' }}>
                Pay Now
            </button>
        </div>
    );
}

const PayBill = () => {
    const [companyName, setCompanyName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [amount, setAmount] = useState(0)
    const ref:string = generateReferenceCode()
    const [reference, setReference] = useState(ref)
    const [clientID, setclientID] = useState('')

    return (
        <div className="col-xl-12 col-lg-12 ">
            <form className="" method="POST" action="" onSubmit={(e) => { e.preventDefault(); submitPayBill({
                token: 'f7faad538d64155945c6f0db14ea75df',
                companyName: companyName,
                phone: phone,
                email: email,
                amount: amount,
                reference: reference,
                clientID: ''
            }) }}>
                <div className="mt-2">
                    <h5 className="oft">Company/Personal Details</h5>
                    <hr className="m-0 p-0" />
                    <div className="row ">
                        <div className="col-md-12 mt-3">
                            <label className="d-block ofl" spellCheck="true"> Customer or Company Name<span className="text-danger">*</span></label>
                            <input name="company_name" type="text" className="form-control order-form" placeholder="Customer or Company Name" onChange={(e) => {setCompanyName(e.target.value)}} required/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="d-block ofl"> Phone Number <span className="text-danger">*</span></label>
                            <input name="phone" type="tel" className="form-control order-form" placeholder="Phone" onChange={(e) => {setPhone(e.target.value)}} required/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="d-block ofl"> Email Address </label>
                            <input name="phone" type="email" className="form-control order-form" placeholder="example@mail.com" onChange={(e) => {setEmail(e.target.value)}} required/>
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="d-block ofl"> Amount <span className="text-danger">*</span></label>
                            <input name="amount" type="number" className="form-control order-form" placeholder="GHS 0.00" step={0.01} min={0} onChange={(e) => {setAmount(Number(e.target.value))}} required/>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <div className="mt-4" >
                            <PaystackPaymentBtn name={companyName} phone={phone} email={email} amount={amount} reference={reference} />
                            <Button type="submit" className="pay_bill_submit_btn" style={{ background: "#8BAC3D", color: '#fff' }}>Proceed</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

function submitPayBill(data:initializeClientForm) {
    lSelector('.pay_bill_submit_btn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
    lSelector('.pay_bill_submit_btn').attr('disabled', 'disabled')

    axios({
        method: "POST",
        url: "https://api.logig.xyz/ovasyte/waste_management/paybill-checker",
        data: data,
        headers: { 'Content-Type': 'application/json', }
    }).then((response: any) => {
        MySwal.fire({
            title: response.data.message.toString(),
            icon: (response.data.status.toString() === 'success' ? 'success' : 'warning'),
            confirmButtonColor: '#8BAC3D',
        })
        if (response.data.status === 'success') {
            finalFormSubmitData.clientID = response.data.clientID
            lSelector('.paystack_form_btn').trigger('click')
        } else {
            lSelector('.pay_bill_submit_btn').html(`Proceed`)
            lSelector('.pay_bill_submit_btn').removeAttr('disabled')
        }
    }).catch((error) => {
        console.log('Paybill checker request failed: ', error)
        lSelector('.pay_bill_submit_btn').html(`Proceed`)
        lSelector('.pay_bill_submit_btn').removeAttr('disabled')
    })
}

function finalFormSubmit() {
    axios({
        method: "POST",
        url: "https://api.logig.xyz/ovasyte/waste_management/paybill",
        data: finalFormSubmitData,
        headers: { 'Content-Type': 'application/json', }
    }).then((response: any) => {
        MySwal.fire({
            title: response.data.message.toString(),
            icon: (response.data.status.toString() === 'success' ? 'success' : 'warning'),
            confirmButtonColor: '#8BAC3D',
        })

        lSelector('.pay_bill_submit_btn').html(`Proceed`)
        lSelector('.pay_bill_submit_btn').removeAttr('disabled')
    }).catch((error) => {
        console.log('Paybill request failed: ', error)
        lSelector('.pay_bill_submit_btn').html(`Proceed`)
        lSelector('.pay_bill_submit_btn').removeAttr('disabled')
    })
}

function onPaystackSuccess(response:any) {
    if (response.reference === finalFormSubmitData.reference) {
        finalFormSubmit()
    } else {
        MySwal.fire(
            'Payment transaction was not successful',
            'warning'
        );
        
        lSelector('.pay_bill_submit_btn').html(`Proceed`)
        lSelector('.pay_bill_submit_btn').removeAttr('disabled')
    }
}

function onPayStackClose() {
    MySwal.fire({
        title: 'Payment has been cancelled',
        icon: 'warning',
        confirmButtonColor: '#8BAC3D',
    })
    lSelector('.pay_bill_submit_btn').html(`Proceed`)
    lSelector('.pay_bill_submit_btn').removeAttr('disabled')
}

function generateReferenceCode():string {
    const sample = shuffle(`qwertyuiopasdfghjklzxcvbnm1234567890`)
    const timestamp = shuffle(getTimeStamp().toString())
    let date = getDateTime().toString().split(' ').join('_')
    return 'waste360_'+date.toString().split(':').join('_')+'_'+sample.substr(10, 4)+'_'+timestamp.substr(0, 4)
}

function shuffle(value:string):string {
    let a = value.toString().split(""), 
    n = a.length;
    for (let i = n - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join("");
}

function getTimeStamp ():number {
    let timestamp = Date.now();
    let time = timestamp.toString().substr((timestamp.toString().length - 8), 8);
    return Number(shuffle(time));
}

function getDateTime ():string {
    let date = new Date();
    let newdate = date.getUTCFullYear()+'-'+(date.getUTCMonth()+1)+'-'+date.getUTCDate()+' '+date.getUTCHours()+':'+date.getUTCMinutes()+':'+date.getUTCSeconds();
    // let todayDate = new Date(newdate);
    // let newTodayDate = dateFormat(todayDate, 'yyyy-mm-dd HH:MM:ss');
    return newdate;
}

export default PayBill;