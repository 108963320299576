import React, { Component } from 'react';


class MainFooter extends Component {

    constructor(props: any) {
        super(props)
        this.setState = () => {
            
        }
    }

    render() {
        return (
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 footer-contact">
                                <p>
                                    Box KM 76 Kwesimintsin<br/>
                                    Takoradi<br/>
                                    Ghana, West Africa <br/><br/>
                                    <strong>Phone:</strong><br/> +233 244 086 646 <br/> +233 312 &nbsp;291  &nbsp;100<br/>
                                    <strong>Email:</strong> info@waste360gh.com<br/>
                                </p>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#about">About Us</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contact Us</a></li>
                                </ul>
                            </div>
                
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="javascript;">Cesspit Emptier Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="javascript;">Communal Container Services</a></li>
                                    <li ><i className="bx bx-chevron-right"></i> <a href="javascript;" style={{lineHeight: '1.4em'}}>House to House waste collection Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="javascript;">Janitorial Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="javascript;">Landscaping and Beautification Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="javascript;">Pest and Vector Control Services</a></li>
                                </ul>
                            </div>
                
                            <div className="col-lg-3 col-md-6 footer-newsletter">
                                <h4>Working Hours</h4>
                                <p className="text-muted">Mon -Fri @ 9am - 5pm</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container d-md-flex py-4">
                    <div className="me-md-auto text-center text-md-start">
                        <div className="copyright">
                            &copy; Copyright <strong><span>Waste360 & Environmental Management Services </span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                        </div>
                    </div>
                    <div className="social-links text-center text-md-right pt-3 pt-md-0">
                        <a href="javascript;" className="twitter"><i className="bx bxl-twitter"></i></a>
                        <a href="javascript;" className="facebook"><i className="bx bxl-facebook"></i></a>
                        <a href="javascript;" className="instagram"><i className="bx bxl-instagram"></i></a>
                        <a href="javascript;" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    </div>
                </div>
            </footer>     
        )
    }
}

export default MainFooter;
