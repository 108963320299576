import React from 'react';
import { Modal, CloseButton } from 'react-bootstrap'

const LearnMoreModal = (props: any) => {
    return (
        <>
            <Modal bsSize="large" show={props.modalOpen} onHide={props.handleModalOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header className="wasteModalHeader">
                    <Modal.Title>Services</Modal.Title>
                    <CloseButton variant="white" onClick={props.handleModalOpen} />
                </Modal.Header>
                <Modal.Body style={{height:'80vh', overflowY: 'auto'}}>
                <p className="text-muted" style={{fontSize: '15px'}}>
                    <ul>
                        <li className="text-dark" ><span style={{fontSize: '15.7px'}}>House to House waste collection Services</span>
                            <p className="text-muted mt-1">The perception of waste as an unwanted material with no intrinsic value has dominated attitudes towards disposal. As a result, it poses a threat to public health and the environment if it is not stored, collected, and disposed of properly.<br/> <span style={{ color: "#8BAC3D" }}>WASTE 360</span> provides you with door to door collection for Households and cooperate institutions. We have a well-trained collection crew with state of the art trucks to serve you better </p>
                        </li>
                        <li className="text-dark"><span style={{fontSize: '15.7px'}}>Janitorial Services</span>
                            <p className="text-muted mt-1"><span style={{ color: "#8BAC3D" }}>WASTE 360</span> is a company built on the foundations of public health, ethics, professionalism, and technical expertise. We are committed to protecting public health through excellence in waste management and Environmental services. We establish vector Janitorial services programs that understand the unique environments and within each institution’s need.  Experience our 360 all-round Janitorial service that takes care of all your needs.</p>
                        </li>
                        <li className="text-dark mt-1"><span style={{fontSize: '15.7px'}}>Pest and Vector Control Services</span>
                            <p className="text-muted mt-1"> Understanding pests' living circumstances and behavior is critical to avoiding them. When assessing the threats and risks to businesses and private residences, Waste 360 Environmental Management Services Limited takes a broad approach to pest control, taking into account the wide range of climate zones, building types, and business types. </p>
                            <p className="text-muted mt-1"> Our methods are based on a variety of activities, and the emphasis is on investigating the underlying causes of an infestation rather than simply treating the problem. Our activities are always aimed at achieving a superior long-term result, and we use the globally recognized principles of Integrated Pest Management (IPM) to minimize environmental impact. </p>
                            <p className="text-muted mt-1"> In these unprecedented times and as part of the effort to help businesses impacted by COVID-19 and the more contagious Delta variant.   Waste 360 is responding to the current global pandemic by offering a groundbreaking cleaning and sanitizing solution for businesses and facilities. Our specialist disinfection solutions provide businesses with an essential resource in light of the COVID-19 pandemic. We offer a range of services, tailored to your needs, to support your business deal with the effects of the coronavirus. </p>
                            <p className="text-muted mt-1"> <span style={{ color: "#8BAC3D" }}>WASTE 360</span> uses a combination and/or selection of products in its COVID cleaning efforts. The disinfectant are :
                                <ul className="mt-1">
                                    <li>Non-abrasive and Non-Corrosive</li>
                                    <li>Non-Flammable - No Harmful Chemicals</li>
                                    <li>Free from Chlorine and Phosphates</li>
                                    <li>Cleans and Disinfects Without Bleaching</li>
                                    <li>No Rinse Required, Even On Food Contact Surfaces</li>
                                    <li>Pleasant Lemongrass Grapefruit Scent</li>
                                    <li>Botanically Derived Active Ingredient</li>
                                </ul>
                            </p>

                        </li>
                        <li className="text-dark mt-1"><span style={{fontSize: '15.7px'}}>Fabrication of Containers.</span>
                            <p className="text-muted mt-1"> Each unit is designed, engineered, and built to client specifications using an old ISO shipping container which is being reused and fabricated into Standard units include 6 to 12 individual toilet stalls, as well as HVAC, electrical, hot and cold water, and hand sinks.</p>
                            <p className="text-muted mt-1"> Excellent for use in the military, construction, oil and gas, disaster relief, and special events. Contact us today to create a toilet solution tailored to your specific requirements.</p>

                        </li>
                        <li className="text-dark mt-1"><span style={{fontSize: '15.7px'}}>Water Tanker Services</span>
                            <p className="text-muted mt-1"> To meet the varying needs of our clients, we’ve variety in our fleet of tankers used to deliver bulk, clean water or portable (drinking) water to site.</p>
                            <p className="text-muted mt-1"> WASTE 360 operates water tankers used for supplying clean water to customers within Western Region and its environs upon request and payment of the requisite service charge. The water tanker provides mobile water supply solution for sites that do not have the water network or are undeserved.</p>
                            <p className="text-muted mt-1">The company implements the highest levels of health and safety in ensuring the tanker vessel is deep cleansed in advance of any water being put into it. Fittings and hoses are cleansed too. Water is drawn from local authority hydrants and tested as fit for purpose.</p>
                        </li>
                        <li className="text-dark mt-2"><span style={{fontSize: '15.7px'}}>Cesspit Emptier Services</span>
                            <p className="text-muted mt-1">Septic tanks can be extremely beneficial in assisting your household in dealing with wastewater in an efficient, cost-effective, and environmentally friendly manner. However, in order to avoid costly problems, they must be cleaned and maintained on a regular basis.</p>
                            <p className="text-muted mt-1">Give the experts at Waste 360 a call if you need your septic tank emptying or cleaning. We can help you empty your septic tanks safely and responsibly, using a combination of powerful vacuumation and tankering equipment, and get everything back in working order as leading drainage contractors and registered waste handlers.</p>
                        </li>
                        <li className="text-dark mt-2"><span style={{fontSize: '15.7px'}}>Artificial Turf Installation</span>
                            <p className="text-muted mt-1">Artificial turf includes a variety of polymers such as polyethylene, polypropylene, nylon, styrene butadiene rubber and polyurethane. Natural materials such as silica sand and calcium carbonate are also present. These materials must be separated in order to be recycled and the variety presents a unique challenge not seen in other recycled materials such as plastic bottles, carpet or plastic bags.</p>
                            <p className="text-muted mt-1">At Waste360, we take pride in providing our customers with high-quality grass and professional finishing/installation which we partner with the manufacturers of the turf for full recyclables. .</p>
                            <p className="text-muted mt-1">Our grass is used in a variety of applications, including sports (football, soccer, golf, and hockey), residential landscaping, commercial spaces (hotels, resorts, restaurants, and so on), and school playgrounds.</p>
                            <p className="text-muted mt-1">We collaborate with reputable architects and landscape designers to transform a drab area of your home into an amazing garden. Nothing beats witnessing the transformation that occurs when we replace a muddy, difficult-to-keep lawn with a pristine artificial grass surface. When deciding on an artificial grass product, keep in mind that quality is everything. </p>
                        </li>
                        <li className="text-dark mt-2"><span style={{fontSize: '15.7px'}}>Landscaping and Beautification Services</span>
                            <p className="text-muted mt-1">Your facility beautification project should create a unique experience for visitors and employees. Everyone is happier and more relaxed when they are close to nature, when spaces are clean and vibrant, and building areas include more places to sit, communicate, or enjoy a moment of quiet time.</p>
                            <p className="text-muted mt-1">Whether you need help with simple, ongoing grounds keeping tasks or full landscape design implementation, our landscaping professionals will get the job done, on time, on budget, and to expectations. See how we can customize our landscaping services to meet your needs.</p>
                            <p className="text-muted mt-1">The landscaping around your building can make a strong impression on your clients, visitors or residents. Let waste360 contribute to your organization’s image with our comprehensive range of beautifying landscaping and grounds keeping services.</p>
                        </li>

                        <li className="text-dark mt-2"><span style={{fontSize: '15.7px'}}>Bulk Refuse Container Service</span>
                            <p className="text-muted mt-1">Waste 360 provide services for quick, convenient removal of large-scale refuse container.  We can set up a regular schedule to empty the containers, or you may simply call us when you need it emptied.</p>
                            <p className="text-muted mt-1">We provide Bulk Refuse Container Service you can count on for safety and consistency. Our drivers go through a rigorous drivers safety course and are equipped to respond immediately to customer service requests. This ensures timely responses to breakdowns, equipment failure and same-day/next-day service requests.</p>
                        </li>
                        <li className="text-dark mt-2"><span style={{fontSize: '15.7px'}}>Communal Container Services</span></li>
                    </ul>
                </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LearnMoreModal;