import React, { Component } from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import LearnMoreModal from './modals/LearnMoreModal';
import lSelector from 'jquery'
import Swal from 'sweetalert2'



class MainBody extends Component {

    state = {
        modalOpen: false,
        token: 'f7faad538d64155945c6f0db14ea75df',
        name: '',
        message: '',
        phone: '',
        email: '',
        subject: ''
    }

    constructor(props: any){
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.submitContact = this.submitContact.bind(this);
    
    }

    handleOnChange = (e: any) => {
        this.setState({ [e.target.name] : e.target.value });
    }

    submitContact(e: any) {
        lSelector('.contact_submit_btn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
        lSelector('.contact_submit_btn').attr('disabled', 'disabled')
        e.preventDefault();
        axios({
            method: "POST",
            url: "https://api.logig.xyz/ovasyte/waste_management/contact_us",
            data: {
                token: this.state.token,
                name: this.state.name,
                message: this.state.message,
                phone: this.state.phone,
                email: this.state.email,
                subject: this.state.subject
            },
            headers: { 'Content-Type': 'application/json', }
        }).then((response: any) => {
            Swal.fire({
                title: response.data.message.toString(),
                icon: (response.data.status.toString() === 'success' ? 'success' : 'warning'),
                confirmButtonColor: '#8BAC3D',
            })
            if (response.data.status === 'success') {
                lSelector('.contact_submit_btn').html(`Send Message`)
            } else {
                lSelector('.contact_submit_btn').html(`Send Message`)
                lSelector('.contact_submit_btn').removeAttr('disabled')
            }
            this.setState({
                token: 'f7faad538d64155945c6f0db14ea75df',
                name: '',
                message: '',
                phone: '',
                email: '',
                subject: ''
            });

            lSelector('.name, .message, .phone, .email, .subject').val('').change()
        })
    }

    resetForm() {
        this.setState({ name: '', email: '', subject: '', message: '' })
    }

    handleModalOpen = () => {
        this.setState((prevState: any) => {
            return {
                modalOpen: !prevState.modalOpen
            }
        })
    }


    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 6
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2
            }
        };
        return (
            <main id="main">
                <section id="clients" className="clients">
                    <div className="container">
                        <div className="row">
                            <Carousel responsive={responsive} autoPlay={true} infinite arrows={false} >

                                <div className="" data-aos="zoom-in" data-aos-delay="100">
                                    <img src="assets/img/clients/vra.webp" className="" alt="" />
                                </div>

                                <div className="" data-aos="zoom-in" data-aos-delay="200">
                                    <img src="assets/img/clients/wayoe.webp" className="" alt="" />
                                </div>

                                <div className="" data-aos="zoom-in" data-aos-delay="300">
                                    <img src="assets/img/clients/gnpc.webp" className="" alt="" />
                                </div>

                                <div className="" data-aos="zoom-in" data-aos-delay="400">
                                    <img src="assets/img/clients/carmeuse.webp" className="" alt="" />
                                </div>

                                <div className="" data-aos="zoom-in" data-aos-delay="500">
                                    <img src="assets/img/clients/harlequin.webp" className="" alt="" />
                                </div>

                                <div className="" data-aos="zoom-in" data-aos-delay="600">
                                    <img src="assets/img/clients/mantrack.svg" className="" alt="" />
                                </div>
                                
                            </Carousel>
                        </div>
                    </div>
                </section>

                {/* <!-- ======= About Section ======= --> */}
                <section id="about" className="about">
                    <div className="container">
                        <div className="row content mt-3">
                            <div className="col-md-6 " data-aos="fade-right" data-aos-delay="100">
                                <img src="assets/img/2 .webp" alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-6 pt-2 pt-lg-0" data-aos="fade-left" data-aos-delay="200">
                                <h5 className="mt-1" style={{ color: "#8BAC3D" }}>About Us</h5>
                                <p className="text-muted text-justify" style={{ fontSize: "15.2px" }}>
                                    We are a fully Ghanaian owned company that focuses on delivering quality Waste Management and Environmental
                                    Services by providing solutions that rely on ongoing technical innovations that respect the environment.
                                </p>
                                <p className="text-muted text-justify" style={{ fontSize: "15.2px" }}>
                                    Above all, we seek first and foremost, to satisfy our clients by
                                    continuously improving our operations to make them efficient and cost effective.
                                    We are wholly committed to building long lasting and closer relationships with our
                                    customers, government, local authorities, the community at large and even our competitors
                                    whom we would prefer to call our partners
                                </p>
                                <p className="text-muted" style={{ fontSize: "15.2px" }}>
                                    We provide waste management services-
                                    door-to-door, communal container, evacuation of heaps,
                                    including drain cleaning , environmental sanitation
                                    services, pest control, janitorial services, & road
                                    sweeping & training of waste management personnel,
                                    landscaping of road sides, medians and roundabouts etc.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 pt-5">
                                <p className="text-muted" style={{ fontSize: "15px" }}>

                                </p>
                            </div>
                        </div>

                    </div>
                </section>

                {/* <!-- ======= Why Us Section ======= --> */}
                <section id="why-us" className="why-us section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-right">
                                <div className="content">
                                    <h3>Why Choose Waste 360 & Environmental Management Services?</h3>
                                    <p style={{ fontSize: "15.2px" }}>
                                        We will establish the appropriate strategies
                                        internally for quality assurance to bear on the services outlined above. It has established the
                                        appropriate organizational set up and administrative arrangements to ensure that all services
                                        are performed according to specifications, performance targets and indicators to be mutually
                                        agreed upon for each service
                                    </p>
                                    <div className="text-center">
                                        <span className="more-btn" role="button" onClick={this.handleModalOpen}>Learn More <i className="bx bx-chevron-right"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 d-flex align-items-stretch">
                                <div className="icon-boxes d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                                            <div className="icon-box mt-4 mt-xl-0">
                                                <i className="bx bx-briefcase-alt"></i>
                                                <h4>Customer Relation</h4>
                                                <p>We prioritize our customer needs while aiming to exceed customer expectations.</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
                                            <div className="icon-box mt-4 mt-xl-0">
                                                <i className="bx bx-chart"></i>
                                                <h4>Performance</h4>
                                                <p>We strive for continuous improvement in our performance, measuring results carefully and ensuring that integrity and respect for people are not compromised.</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                            <div className="icon-box mt-4 mt-xl-0">
                                                <i className="bx bx-pin"></i>
                                                <h4>Integrity</h4>
                                                <p>We believe in honesty and uphold strong moral principles in our service delivery.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- ======= Cta Section ======= --> */}
                <section id="cta" className="cta">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 wasteCtaSection">
                                <div className="text-center" data-aos="zoom-in">
                                    <h3>Mission</h3>
                                    <p className="mt-4"> Our mission is to be at the Lead of the environmental sanitation services industry in all
                                        the MMDAs and large institutions in Ghana, by introducing modernize but cost effective
                                        systems of waste collection and Environmental Management services
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center" data-aos="zoom-in">
                                    <h3>Vision</h3>
                                    <p className="mt-4"> To be a Lead in the provision of an Excellent Waste and Environmental Management Services in all of Africa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- ======= Services Section ======= --> */}
                <section id="services" className="services section-bg">
                    <div className="container">

                        <div className="row">

                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                                            <img src="assets/img/services/3.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">House to House waste collection Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>The perception of waste as an unwanted material with no intrinsic value has dominated attitudes towards disposal. As a result, it poses a threat to public health and the environment if it is not stored, collected, and disposed of properly. WASTE 360 provides you with door to door collection for Households and cooperate institutions. We have a well-trained collection crew with state of the art trucks to serve you better </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="400">
                                            <img src="assets/img/services/4.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Janitorial Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Waste 360 is a company built on the foundations of public health, ethics, professionalism, and technical expertise. We are committed to protecting public health through excellence in waste management and Environmental services. We establish vector Janitorial services programs that understand the unique environments and within each institution’s need.  Experience our 360 all-round Janitorial service that takes care of all your needs. </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="600">
                                            <img src="assets/img/services/6.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Pest and Vector Control Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Understanding pests' living circumstances and behavior is critical to avoiding them. When assessing the threats and risks to businesses and private residences, Waste 360 Environmental Management Services Limited takes a broad approach to pest control, taking into account the wide range of climate zones, building types, and business types.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                                            <img src="assets/img/services/1.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Cesspit Emptier Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Give the experts at Waste 360 a call if you need your septic tank emptying or cleaning. We can help you empty your septic tanks safely and responsibly, using a combination of powerful vacuumation and tankering equipment, and get everything back in working order as leading drainage contractors and registered waste handlers</p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
                                            <img src="assets/img/services/tanker.png" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Water Tanker Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>WASTE 360 operates water tankers used for supplying clean water to customers within Western Region and its environs upon request and payment of the requisite service charge. The water tanker provides mobile water supply solution for sites that do not have the water network or are undeserved.</p>
                                        </div>
                                    </div>

                                    

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
                                            <img src="assets/img/services/5.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Landscaping and Beautification Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Your facility beautification project should create a unique experience for visitors and employees. Everyone is happier and more relaxed when they are close to nature, when spaces are clean and vibrant, and building areas include more places to sit, communicate, or enjoy a moment of quiet time.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
                                            <img src="assets/img/services/astro turf.png" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Artificial Turf Installation</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>At Waste360, we take pride in providing our customers with high-quality grass and professional finishing/installation which we partner with the manufacturers of the turf for full recyclables. 
                                            Our grass is used in a variety of applications, including sports (football, soccer, golf, and hockey), residential landscaping, commercial spaces (hotels, resorts, restaurants, and so on), and school playgrounds.</p>
                                        </div>
                                    </div>

                                    

                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
                                            <img src="assets/img/services/waste.png" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Bulk Refuse Container Service</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Waste 360 provide services for quick, convenient removal of large-scale refuse container.  We can set up a regular schedule to empty the containers, or you may simply call us when you need it emptied.
                                            We provide Bulk Refuse Container Service you can count on for safety and consistency. Our drivers go through a rigorous drivers safety course and are equipped to respond immediately to customer service requests.</p>
                                        </div>
                                    </div>


                                    <div className="col-md-4 d-flex align-items-stretch mt-4">
                                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                                            <img src="assets/img/services/2.webp" className="img-fluid" alt="" style={{ width: "8em" }} />
                                            <h4 className="mt-4">Communal Container Services</h4>
                                            <p className="text-muted" style={{ fontSize: "15.2px" }}>Our Container Services help to safely manage your waste from collection, documentation, tracking, transportation to final safe disposal</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="renewal" className="about">
                    <div className="container">
                        <div className="row content mt-3">
                            <div className="col-md-6 " data-aos="fade-right" data-aos-delay="100">
                                <img src="assets/img/renewal-image.webp" alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-6 pt-2 pt-lg-0" data-aos="fade-left" data-aos-delay="200">
                                <h5 className="mt-1" style={{ color: "#8BAC3D" }}>Renewal Energy</h5>
                                <p className="text-muted text-justify" style={{ fontSize: "15.2px" }}>
                                    Africa is disproportionately affected by climate change, this leads to more frequent and severe droughts, 
                                    floods and extreme weather events. The high cost of electricity in Africa, both on and off grid has become a 
                                    significant concern for businesses and households which is greatly hindering economic development at a 
                                    significant rate. In the past year, most African countries have increased utilities over 70%.
                                </p>
                                <p className="text-muted text-justify" style={{ fontSize: "15.2px" }}>
                                    We at Waste 360 together with selected partners have solutions to reduce greenhouse gases by introducing a 
                                    wide range of renewable energy ranging from Solar Power, Wind Power, hydroelectric Power, waste to Energy, 
                                    BioEnergy and Geothermal.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- ======= Portfolio Section ======= --> */}
                <section id="portfolio" className="portfolio">
                    <div className="container">

                        <div className="section-title" data-aos="fade-left">
                            <h2>GALLERY</h2>
                        </div>

                        <div className="row " style={{ height: "100%" }} data-aos="fade-up" data-aos-delay="200">
                            <SRLWrapper>
                                <img role="button" src="assets/img/portfolio/1.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/2.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/3.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/4.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/5.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/6.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/7.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/8.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/9.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />
                                
                                <img role="button" src="assets/img/portfolio/turf1.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/turf2.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/turf3.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />
                                
                                <img role="button" src="assets/img/portfolio/turf9.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />


                                <img role="button" src="assets/img/portfolio/turf11.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/turf12.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                                <img role="button" src="assets/img/portfolio/turf13.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />
                                
                                <img role="button" src="assets/img/portfolio/turf14.webp" className="img-fluid col-md-3 p-3 wasteImg" alt="" />

                            </SRLWrapper>
                        </div>

                    </div>
                </section>

                {/* <!-- ======= Contact Section ======= --> */}
                <section id="contact" className="contact section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h2>Let's get talking...</h2>
                                    <p>Contact us for guaranteed response within one business day.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4" data-aos="fade-right">
                                <div className="row">
                                    <div className="col-lg-12 mt-4">

                                        <div className="info">
                                            <i className="bi bi-geo-alt"></i>
                                            <h4>Location:</h4>
                                            <p>Adwoa Frema Mall, Chapel Hill-Takoradi</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <div className="info">
                                            <i className="bi bi-envelope"></i>
                                            <h4>Email:</h4>
                                            <p>info@waste360gh.com</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="info w-100 mt-4">
                                            <i className="bi bi-phone"></i>
                                            <h4>Call:</h4>
                                            <p>+233 312 291 100</p>
                                            <p>+233 244 086 646</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="100">
                                <form method="post" className="php-email-form mt-4" onSubmit={this.submitContact.bind(this)}>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Name or Company Name" required value={this.state.name} onChange={this.handleOnChange}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mt-3 ">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="example@mail.com" required  value={this.state.email} onChange={this.handleOnChange}/>
                                        </div>
                                        <div className="col-md-6 form-group mt-3 ">
                                            <input type="tel" className="form-control" name="phone" id="phone" placeholder="Phone" required value={this.state.phone} onChange={this.handleOnChange}/>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <select className="form-control" onChange={this.handleOnChange} value={this.state.subject} name="subject">
                                            <option value="Default"> Subject *</option>
                                            <option value="service"> Request for service </option>
                                            <option value="complaint"> Log in complaint</option>
                                            <option value="recommendation"> Recommendation</option>
                                            <option value="wwaste collection"> Request for Household or Industrial Waste Collection</option>
                                        </select>
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" placeholder="Message" value={this.state.message} rows={3} required onChange={this.handleOnChange}></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center mt-4 submit" ><button type="submit" className="contact_submit_btn">Send Message</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="col-md-12 container">
                            <iframe title="Waste 360 & Environmental Management Services Office" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d343.67776353094644!2d-1.7549177979072248!3d4.886423810439967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfe77757e6edac5d%3A0x86631bcc3a086600!2sWaste360%20Environmental%20Services%20Ghana%20Limited!5e0!3m2!1sen!2sgh!4v1723943257154!5m2!1sen!2sgh" style={{ width: "100%", height: "450", border: 'none' }} loading="lazy"></iframe>
                        </div>
                    </div>
                </section>
                <LearnMoreModal 
                modalOpen={this.state.modalOpen}
                handleModalOpen={this.handleModalOpen}/>
            </main >
        )
    }
}

export default MainBody;
