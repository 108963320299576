import React from 'react';
import { Modal, CloseButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const RequestModal = (props: any) => {
    return (
        <>
            <Modal show={props.modalOpen} onHide={props.handleModalOpen} aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header className="wasteModalHeader">
                    <Modal.Title>Request</Modal.Title>
                    <CloseButton variant="white" onClick={props.handleModalOpen} />
                </Modal.Header>
                <Modal.Body style={{ height: '27em', overflowY: 'auto' }}>
                    <div className="row text-center mt-4">
                        <div className="col-md-6">
                            <NavLink to="/request-for-quotation">
                                <img src="assets/img/services/slice 4.png" className="img-fluid" alt="" style={{ width: "6.6em" }} />
                                <p className="text-dark" style={{ fontSize: '1.2em' }}>Request For Quotation</p>
                            </NavLink>
                        </div>
                        <div className="col-md-6">
                            <NavLink to="/request-for-service">
                                <img src="assets/img/services/7.webp" className="img-fluid" alt="" style={{ width: "9.2em" }} />
                                <p className="text-dark" style={{ fontSize: '1.2em' }}>Request For Service</p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row text-center mt-4">
                        <div className="col-md-6">
                            <NavLink to="/client-registration">
                                <img src="assets/img/services/slice 6 .png" className="img-fluid" alt="" style={{ width: "7.3em" }} />
                                <p className="text-dark" style={{ fontSize: '1.2em' }}>Client Registration</p>
                            </NavLink>
                        </div>
                        <div className="col-md-6">
                            <NavLink to="/pay-bill">
                                <img src="assets/img/services/PayBill.png" className="img-fluid" alt="" style={{ width: "7em" }} />
                                <p className="text-dark" style={{ fontSize: '1.2em' }}>Pay Bill</p>
                            </NavLink>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RequestModal;