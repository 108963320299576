import React, { Component } from 'react';
import { NavLink} from 'react-router-dom';

class Breadcrumb extends Component {
    

    render() {
        // get CurrentURL
        const currentURL = window.location.pathname.replace('/','').replaceAll('-', ' ');
        return (
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 style={{textTransform: 'capitalize'}}>{currentURL}</h2>
                        <ol>
                            <li><NavLink to="/" >Home</NavLink></li>
                            <li style={{textTransform: 'capitalize'}}>{currentURL}</li>
                        </ol>
                    </div>
                </div>
            </section>
        )
    }


}

export default Breadcrumb;
