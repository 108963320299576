import React, { Component } from 'react';
import PayBillForm from '../forms/PayBillForm';
import Breadcrumb from '../navs/Breadcrumb';
import Footer from '../navs/Footer';
import NavBar from '../navs/Navbar';

class PayBill extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Breadcrumb />
                <div>
                    <section className="portfolio-details mt-n5" style={{ padding: '20px' }}>
                        <div className="container">
                            <div className="row" style={{ marginBottom: '0' }}>
                                <div className="col-md-4" data-aos="fade-up">
                                    <img src="assets/img/services/PayBill.png" className="img-fluid" alt="" style={{ width: "15em" }} />
                                    <div className="portfolio-description">
                                        <p>We’re pleased to offer you a variety of convenient, secure payment options for your services.<br/>Please enter your company details to proceed.</p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <section id="blog" className="blog">
                                        <div className="container-fl">
                                            <div className="row">
                                                <div className="col-lg-12  col-md-10 d-flex align-items-stretch entry" data-aos="fade-up">
                                                    <PayBillForm />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }

}

export default PayBill;
