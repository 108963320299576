import React, { Component } from 'react';
import Breadcrumb from '../navs/Breadcrumb';
import Footer from '../navs/Footer';
import NavBar from '../navs/Navbar';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

class Blog extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Breadcrumb />
                <div className="container mb-5">
                    <div className="row ">
                        <div className="col-md-3 mt-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src="assets/img/portfolio/4.webp" />
                                <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text >
                                        Some quick example text to build on the card title and make up the bulk of
                                        the card's content.
                                    </Card.Text>
                                    <Button type="button" style={{ background: "#8BAC3D", color: '#fff' }} variant="">Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src="assets/img/portfolio/1.webp" />
                                <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the bulk of
                                        the card's content.
                                    </Card.Text>
                                    <Button type="button" style={{ background: "#8BAC3D", color: '#fff' }} variant="">Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src="assets/img/portfolio/2.webp" />
                                <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the bulk of
                                        the card's content.
                                    </Card.Text>
                                    <Button type="button" style={{ background: "#8BAC3D", color: '#fff' }} variant="">Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src="assets/img/portfolio/5.webp" />
                                <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the bulk of
                                        the card's content.
                                    </Card.Text>
                                    <Button type="button" style={{ background: "#8BAC3D", color: '#fff' }} variant="">Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }


}

export default Blog;
