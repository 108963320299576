import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Blog from './pages/Blog';
import IndexPage from './pages/Index';
import PayBill from './pages/PayBill';
import Register from './pages/Register';
import RequestQuotation from './pages/RequestQuotation';
import RequestService from './pages/RequestService';


class Waste360 extends Component {

    constructor(props: any) {
        super(props)
        this.setState = () => {

        }
    }

    render() {
        return (
            <div>
                <Router>
                    <Route exact path="/" component={IndexPage} />
                    <Route exact path="/pay-bill" component={PayBill} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/request-for-quotation" component={RequestQuotation} />
                    <Route exact path="/request-for-service" component={RequestService} />
                    <Route exact path="/client-registration" component={Register} />
                </Router>
            </div>
        )
    }
}

export default Waste360;
