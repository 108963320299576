import React, { Component } from 'react';

class Hero extends Component {


    render() {
        return (
            < section id="hero" className="d-flex align-items-center" >
                <div className="container text-center position-relative" data-aos="fade-in" data-aos-delay="200">
                    <h1 >Premium Waste & Environmental Management <br />Services Providers</h1>
                    <p className="text-white " style={{ fontSize: '18px', paddingTop: '13px', textAlign: 'center' }}><b>Providing waste & sanitory solution that rely on  on-going  Environmental friendly technical innovations.</b></p>
                </div>
            </section >
        )
    }


}

export default Hero;
