import React from 'react'
import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap'
import lSelector from 'jquery'


const MySwal = withReactContent(Swal)
class RegistrationForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.submitRegistration = this.submitRegistration.bind(this);

        this.state = {
            token: 'f7faad538d64155945c6f0db14ea75df',
            name_of_company: '',
            phone: '',
            email: '',
            address: '',
            service: '',
            district: '',
            area: '',
            serviceDropdown: [],
            categoryDropdown: [],
            subcategoryDropdown: [],
        }
    }

    handleOnChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        // Get Service Data
        axios.get("https://api.logig.xyz/ovasyte/waste_management/service", {params: {token: 'f7faad538d64155945c6f0db14ea75df'}})
        .then(res => {
            const data = res.data;
            this.setState({ serviceDropdown: data });
        })

        // Get District Data
        axios.get("https://api.logig.xyz/ovasyte/waste_management/category", {params: {token: 'f7faad538d64155945c6f0db14ea75df'}})
        .then(res => {
            const data = res.data;
            this.setState({ categoryDropdown: data });
        })

        // Get Area Data
        axios.get("https://api.logig.xyz/ovasyte/waste_management/subcategory", {params: {token: 'f7faad538d64155945c6f0db14ea75df'}})
        .then(res => {
            const data = res.data;
            this.setState({ subcategoryDropdown: data });
        })
        
    }

    submitRegistration(e: any) {
        lSelector('.registration_btn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
        lSelector('.registration_btn').attr('disabled', 'disabled')
        e.preventDefault();
        axios({
            method: "POST",
            url: "https://api.logig.xyz/ovasyte/waste_management/client_registration",
            data: this.state,
            headers: { 'Content-Type': 'application/json', }
        }).then((response: any) => {
            MySwal.fire({
                title: response.data.message.toString(),
                icon: (response.data.status.toString() === 'success' ? 'success' : 'warning'),
                confirmButtonColor: '#8BAC3D',
            })
            if (response.data.status === 'success') {
                lSelector('.registration_btn').html(`Submit`)
                lSelector('.registration_btn').removeAttr('disabled')
            } else {
                lSelector('.registration_btn').html(`Submit`)
                lSelector('.registration_btn').removeAttr('disabled')
            }
            this.setState({
                token: 'f7faad538d64155945c6f0db14ea75df',
                name_of_company: '',
                phone: '',
                email: '',
                address: '',
                service: '',
                district: '',
                area: ''
            });
        })
    }

    render() {
        // Service Dropdown
        const serviceData = this.state.serviceDropdown
        let serviceDropdownOptions = serviceData.length > 0
        && serviceData.map((item:any, i:any) => {
            return (
                <option key={i} value={item.serviceid}>{item.name}</option>
            )
        }, this);

        // District Dropdown
        const categoryData = this.state.categoryDropdown
        let districtDropdownOptions = categoryData.length > 0
        && categoryData.map((item:any, i:any) => {
            return (
                <option key={i} value={item.categoryid}>{item.name}</option>
            )
        }, this);

        // Area Dropdown
        const subcategoryData = this.state.subcategoryDropdown
        let areaDropdownOptions = subcategoryData.length > 0
        && subcategoryData.map((item:any, i:any) => {
            return (
                <option key={i} value={item.sub_categoryid}>{item.name}</option>
            )
        }, this);
        return (
            <div className="col-xl-12 col-lg-12 ">
                <form className="" method="POST" action="" onSubmit={this.submitRegistration.bind(this)}>
                    <div className="mt-2">
                        <h5 className="oft">Company Details</h5>
                        <hr className="m-0 p-0" />
                        <div className="row ">
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl"> Name or Company Name<span className="text-danger">*</span></label>
                                <input name="name_of_company" className="form-control order-form" type="text" placeholder="Name or Company Name" value={this.state.name_of_company} onChange={this.handleOnChange} spellCheck="true" required />
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl"> Phone <span className="text-danger">*</span></label>
                                <input name="phone" className="form-control order-form" type="tel" placeholder="Phone" required value={this.state.phone} onChange={this.handleOnChange}/>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl"> Email</label>
                                <input name="email" type="email" className="form-control order-form" placeholder="example@mail.com" value={this.state.email} onChange={this.handleOnChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <label className="d-block ofl"> Address</label>
                                <textarea name="address" className="form-control order-form" placeholder="Address" rows={2} required value={this.state.address} onChange={this.handleOnChange}></textarea>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl" spellCheck="true"> Service </label>
                                <select name="service" className="form-control order-form" onChange={this.handleOnChange} value={this.state.service}  >
                                    <option value="" selected>Select Service</option>
                                    {serviceDropdownOptions}
                                </select>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl" spellCheck="true"> District </label>
                                <select name="district" className="form-control order-form" onChange={this.handleOnChange} value={this.state.district} required>
                                    <option value="" selected> Select District </option>
                                    {districtDropdownOptions}
                                </select>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl" spellCheck="true"> Area <span className="text-danger">*</span></label>
                                <select name="area" className="form-control order-form" onChange={this.handleOnChange} value={this.state.area} required>
                                    <option value="" selected> Select Area </option>
                                    {areaDropdownOptions}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-md-6 mt-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button type="submit" className="registration_btn" style={{ background: "#8BAC3D", color: '#fff', height: '45px', marginTop: '1.1em', alignContent: 'right' }} variant="">Submit</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

export default RegistrationForm;
