import React from 'react';
import axios from "axios"
// import RequestQuotationTable from '../tables/RequestQuotation';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap'
import lSelector from 'jquery'

const MySwal = withReactContent(Swal)

class RequestQuotationForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.submitQuotation = this.submitQuotation.bind(this);
        this.removeService = this.removeService.bind(this);

        this.state = {
            token: 'f7faad538d64155945c6f0db14ea75df',
            name_of_company: '',
            phone: '',
            email: '',
            service: '',
            serviceDropdown: [],
            description: '',
            serviceArray: []
        }
    }

    // Mount
    componentDidMount() {
        // Get Service Data
        axios.get("https://api.logig.xyz/ovasyte/waste_management/service", {params: {token: 'f7faad538d64155945c6f0db14ea75df'}})
        .then(res => {
            const data = res.data;
            this.setState({ serviceDropdown: data });
        })
        
    }
    
    // Handle On Change
    handleOnChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // Adding Service to DataTable
    addService = (e: any) => {
        if (this.state.service === null || this.state.service === undefined || this.state.service === '') {
            MySwal.fire({
                title: 'Service field is required',
                icon: 'warning',
                confirmButtonColor: '#8BAC3D',
            })
        } else {
            this.state.serviceArray.push({
                service: this.state.service,
                description: this.state.description,
            });
            this.setState({ serviceArray: this.state.serviceArray });
            this.setState({
                service: '',
                description: ''
            });
        }
    }

    // Removing Service to DataTable
    removeService = (e: any) => {
        const removeId = e.target.dataset.remove;
        const items = this.state.serviceArray;
        for (let i = items.length - 1; i >= 0; --i) {
            if (items[i].service.split('**')[0] === removeId) items.splice(i, 1);
        }
        this.setState({serviceArray: items});
    }

    // Request for Quotation Submit
    submitQuotation(e: any) {
        lSelector('.rfq_submit_btn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
        lSelector('.rfq_submit_btn').attr('disabled', 'disabled')
        e.preventDefault();
        axios({
            method: "POST",
            url: "https://api.logig.xyz/ovasyte/waste_management/request_quotation",
            data: {
                token: this.state.token,
                name_of_company: this.state.name_of_company,
                phone: this.state.phone,
                email: this.state.email,
                serviceArray: this.state.serviceArray
            },
            headers: { 'Content-Type': 'application/json', }
        }).then((response: any) => {
            MySwal.fire({
                title: response.data.message.toString(),
                icon: (response.data.status.toString() === 'success' ? 'success' : 'warning'),
                confirmButtonColor: '#8BAC3D',
            })
            if (response.data.status === 'success') {
                lSelector('.rfq_submit_btn').html(`Request for Quotation`)
                lSelector('.rfq_submit_btn').removeAttr('disabled')
            } else {
                lSelector('.rfq_submit_btn').html(`Request for Quotation`)
                lSelector('.rfq_submit_btn').removeAttr('disabled')
            }
            this.setState({
                token: 'f7faad538d64155945c6f0db14ea75df',
                name_of_company: '',
                phone: '',
                email: '',
                service: '',
                description: '',
                serviceArray: []
            });
        })
    }

    // Render Form
    render() {
        // Service Dropdown
        const serviceData = this.state.serviceDropdown
        let serviceDropdownOptions = serviceData.length > 0
        && serviceData.map((item:any, i:any) => {
            return (
                <option key={i} value={item.serviceid+'**'+item.name}>{item.name}</option>
            )
        }, this);

        // Service DataTable
        const serviceDataTable = this.state.serviceArray;
        let serviceTableRows;
        if (serviceDataTable.length === 0) {
            serviceTableRows = 
                <tr>
                    <td colSpan={4} style={{ fontSize: '16px'}}>No Data Available</td>
                </tr> 
        } else {
        serviceTableRows = serviceDataTable.length > 0 
        && serviceDataTable.map((item:any, i:any)  => {
            return (
                <tr>
                    <td>{i+1}</td>
                    <td>{item.service.split('**')[1]}</td>
                    <td>{item.description}</td>
                    <td><Button variant="danger" onClick={this.removeService} data-remove={item.service.split('**')[0]}>x</Button></td>
                </tr>
            );
        });
        }
        return (
            <div className="col-xl-12 col-lg-12 ">
                <form className="" method="POST" action="" onSubmit={this.submitQuotation.bind(this)}>
                    <div className="mt-2">
                        <h5 className="oft">Company Details</h5>
                        <hr className="m-0 p-0" />
                        <div className="row ">
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl" spellCheck="true"> Name or Company Name<span className="text-danger">*</span></label>
                                <input name="name_of_company" type="text" className="form-control order-form" placeholder="Name or Company Name" value={this.state.name_of_company} onChange={this.handleOnChange}/>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl"> Phone <span className="text-danger">*</span></label>
                                <input name="phone" type="tel" className="form-control order-form" placeholder="Phone" value={this.state.phone}  onChange={this.handleOnChange}/>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="d-block ofl"> Email <span className="text-danger">*</span></label>
                                <input name="email" type="email" className="form-control order-form" placeholder="example@mail.com" value={this.state.email} onChange={this.handleOnChange}/>
                            </div>
                        </div>
                        <h5 className="oft mt-4">Services</h5>
                        <hr className="m-0 p-0" />
                        <div className="w-100 row">
                            <div className="col-md-4 col-sm-4 mt-3">
                                <label className="d-block ofl" spellCheck="true"> Service<span className="text-danger">*</span></label>
                                <select name="service" className="form-control order-form" onChange={this.handleOnChange} value={this.state.service} >
                                    <option value="" >Select Service</option>
                                    {serviceDropdownOptions}
                                </select>
                            </div>
                            <div className="col-md-7 col-sm-4 mt-3">
                                <label className="d-block ofl"> Description </label>
                                <textarea  name="description" className="form-control order-form" placeholder="Description" rows={1}  value={this.state.description} onChange={this.handleOnChange}></textarea>
                            </div>
                            <div className="col-md-1 col-sm-4 mt-3">
                                <button type="button" className="btn  border" style={{ background: '#8BAC3D', color: '#fff', height: '45px', marginTop: '1.1em'}} onClick={this.addService}>Add</button>
                            </div>
                        </div>
                        {/* TABLE */}
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <table className="table table-responsive table-striped">
                                    <thead style={{ background: '#8BAC3D', color: '#fff' }}>
                                        <tr>
                                            <th>#</th>
                                            <th>Service</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceTableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <div className="mt-4" >
                                <button type="submit" className="btn rfq_submit_btn" style={{ background: '#8BAC3D', color: '#fff', height: '45px', margin: 'auto'}}>Request for Quotation</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default RequestQuotationForm;
