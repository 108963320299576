import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import RequestModal from '../modals/RequestModal';

class NavBar extends Component {

    state = {
        modalOpen: false
    }

    constructor(props: any) {
        super(props);

        this.handleMobileMenu = this.handleMobileMenu.bind(this);
    }

    handleMobileMenu = (e: any) => {
        e.target.parentElement.classList.toggle('navbar-mobile');
        e.target.classList.toggle('bi-list');
        e.target.classList.toggle('bi-x');
    }

    handleModalOpen = () => {
        this.setState((prevState: any) => {
            return {
                modalOpen: !prevState.modalOpen
            }
        })
    }

    render() {
        return (
            <header id="header" className="fixed-top d-flex align-items-center">
                <div className="container">
                    <div className="header-container d-flex align-items-center justify-content-between">
                        <div className="logo">
                            {/* <h1 className="text-light"><a href="/"><span>Bethany</span></a></h1> */}
                            {/* <!-- Uncomment below if you prefer to use an image logo --> */}
                            <a href="/"><img src="assets/img/logo.webp" alt="" className="img-fluid wasteLogo" /></a>
                        </div>

                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><NavLink to="/" className="nav-link scrollto">Home</NavLink></li>
                                <li><a className="nav-link scrollto" href="/#about">About</a></li>
                                {/* <li><NavLink to="/blog" className="nav-link scrollto">Blog</NavLink></li> */}
                                <li><a className="nav-link scrollto" href="/#services">Services</a></li>
                                <li><a className="nav-link scrollto " href="/#portfolio">Gallery</a></li>
                                <li><a className="nav-link scrollto " href="/#contact">Contact Us</a></li>
                                <li id="shake"><span className="getstarted scrollto" role="button" onClick={this.handleModalOpen}>Request</span></li>
                            </ul>
                            <i className="bi bi-list mobile-nav-toggle scrollto" onClick={this.handleMobileMenu}></i>
                        </nav>

                    </div>
                </div>
                <RequestModal modalOpen={this.state.modalOpen}
                    handleModalOpen={this.handleModalOpen} />
            </header>
        )
    }


}

export default NavBar;
